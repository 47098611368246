<template>
    <div class="detail_box" v-loading="loading">
        <div class="order_no">
            提单号：{{info.bl_no || '-'}}
        </div>
        <div class="car_info">
            <div class="title">
                车辆信息
            </div>
            <div class="infoList">
               
                <div class="infoItem">
                    <div class="itemFl">
                        进仓编号：
                    </div>
                    <div class="itemFr">
                        {{info.warehouse_no || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                       VIN码：
                    </div>
                    <div class="itemFr">
                        {{info.vin || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        委托单位：
                    </div>
                    <div class="itemFr">
                        {{info.customer_name_zh || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        品牌：
                    </div>
                    <div class="itemFr">
                        {{info.brand || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        车型：
                    </div>
                    <div class="itemFr">
                        {{info.vehicle_model || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        配置：
                    </div>
                    <div class="itemFr">
                        {{info.vehicle_config || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        重量：
                    </div>
                    <div class="itemFr">
                        {{info.weight || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        发动机：
                    </div>
                    <div class="itemFr">
                        {{info.vehicle_engine || '-'}}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                        入库人员：
                    </div>
                    <div class="itemFr">
                        {{info.in_wh_user || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                       入库时间：
                    </div>
                    <div class="itemFr">
                        {{info.in_wh_time | secondFormat() }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                       装箱时间：
                    </div>
                    <div class="itemFr">
                        {{info.packing_time | secondFormat() }}
                    </div>
                </div>

                <div class="infoItem">
                    <div class="itemFl">
                        集装箱号：
                    </div>
                    <div class="itemFr">
                        {{info.box_no || '-' }}
                    </div>
                </div>
                <div class="infoItem">
                    <div class="itemFl">
                       状态：
                    </div>
                    <div class="itemFr">
                        <el-tag type="warning" v-if="info.status == 1">待入</el-tag>
                        <el-tag  v-if="info.status == 2">入库</el-tag>
                        <el-tag type="success" v-if="info.status == 3">装箱</el-tag>
                    </div>
                </div>
            </div>
        </div>

        <div class="car_info">
            <div class="title">
                进仓图片    <el-button size="small" type="primary" @click="downImg" style="margin-left: 20px;" :loading="btnLoading"  v-if="info.imgs && info.imgs.length">下载全部照片</el-button>
            </div>
            <div class="imgList" v-if="info.imgs && info.imgs.length">
                <el-image class="img" v-for="(item, index) in info.imgs" :key="index" style="width: 140px; height: 140px"
                    :src="item" fit="cover" :preview-src-list="info.imgs">
                </el-image>
            </div>
            <div class="noData" v-else>
                暂无图片
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {

    },
    data() {
        return{
            info:{},
            btnLoading:false,
            loading:false
        }
    },
    mounted() {
        this.getCarDetaila()
    },
    methods: {
        async getCarDetaila(){
            this.loading= true
            let res = await this.$store.dispatch("API_warehouse/getCarDetaila", {
                id:this.$route.query.id
            });
            this.loading= false
            if(res.success){
                res.data.imgs=res.data.in_wh_image.map(ee=>{return ee.url})
                this.info = res.data
            }
        },
        async downImg() {
            this.btnLoading = true
            let data = await this.$store.dispatch('API_warehouse/downImg', {
                files_url: JSON.stringify(this.info.imgs),
                download_source: '进仓图片'
            })
            window.open(data.data)
            this.btnLoading = false
        },
    }

}
</script>
  
<style lang="less" scoped>
/deep/ .el-button--primary {
    background: #91be42 !important;
    border: none;
    margin-left: 20px;
}
.noData{
    line-height: 150px;
    text-align: center;
    font-size: 14px;
    color: #999;
}
.imgList {
    padding: 30px  30px;
    min-height: 100px;
    .img {
        margin: 5px 5px;
    }
}


.detail_box {
     width: 100%;
     min-height: calc(100vh - 129px);
     margin: 0 auto;
     background: #FBFCFA;
     padding: 30px 0;

     .order_no {
         width: 1300px;
         padding: 30px 25px;
         background: #FFF;
         margin: 0px auto 30px;
         font-size: 16px;
         font-weight: 600;
     }

 }

 .car_info {
    width: 1300px;
    margin: 30px auto;
    background: #FFF;
     .title {
        font-size: 16px;
        line-height: 60px;
        text-indent: 20px;
        font-weight: 600;
        border-bottom: 1px solid #eee;
     }
 }
 .infoList{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    .infoItem{
        width: 50%;
        padding: 0 20px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        .itemFl{
            width: 90px;
        }
    }
 }

</style>